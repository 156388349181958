<script setup lang="ts">
import type { PropType } from "vue";

import { useAbility } from "@casl/vue";
import { permissoesEnum } from "@/types/enums";

const { can } = useAbility();

const { clientVersion } = useRuntimeConfig();

type Item = {
  label: string;
  icon: string;
  route: string;
  permissions: string[];
};

const props = defineProps({
  items: {
    type: Array as PropType<Item[]>,
    required: false,
    default: () => [],
  },
});

const open = ref(true);

const toggleSideBar = () => {
  open.value = !open.value;
};

const filteredItems = computed((): Item[] =>
  props.items.filter((item: Item) =>
    item.permissions.some((permission) =>
      can(permissoesEnum.VER_ROTA, permission)
    )
  )
);

const routeName = computed(
  (): string => String(useRouter().currentRoute.value.name)?.split("-")[0]
);
</script>

<style>
.custom-shadow {
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
}
</style>

<template>
  <aside
    :class="{
      'w-64': open,
      'w-20': !open,
    }"
    class="custom-shadow sticky top-0 left-0 z-50 flex h-screen flex-col bg-slate-50 transition-all"
  >
    <!-- Logo -->
    <NuxtLink
      to="/"
      class="mb-5 flex w-full flex-row justify-center py-7"
      :class="open ? 'justify-start pr-10' : ''"
    >
      <img v-show="!open" class="h-12" src="/cadastro-logo.png" />
      <img v-show="open" class="h-12" src="/cadastro-logo-escrito.png" />
    </NuxtLink>

    <!-- Icon to open -->
    <button
      @click="toggleSideBar"
      class="absolute top-20 right-[-12px] grid h-6 w-6 cursor-pointer place-items-center rounded-full border border-slate-200 bg-white shadow-lg hover:shadow-xl"
    >
      <UiIconAngleDown
        class="h-4 w-4 transform text-cad-magenta-500"
        :class="open ? 'rotate-90' : '-rotate-90'"
      />
    </button>

    <ul class="flex flex-col items-center">
      <template v-for="(item, i) in filteredItems" :key="i">
        <NuxtLink :to="item.route" class="w-full">
          <li
            class="flex w-full flex-row items-center space-x-3 py-5 pl-7 transition-colors"
            :class="[
              item.route.includes(routeName)
                ? 'bg-cad-magenta-600'
                : 'hover:bg-cad-magenta-100',
            ]"
          >
            <component
              :is="item.icon"
              :class="[
                'h-6 w-6 flex-none',
                item.route.includes(routeName)
                  ? 'text-white'
                  : 'text-cad-magenta-500',
              ]"
            />
            <span
              v-show="open"
              :class="[
                'truncate font-medium',
                item.route.includes(routeName)
                  ? 'text-white'
                  : 'text-slate-500',
              ]"
            >
              {{ item.label }}
            </span>
          </li>
        </NuxtLink>
      </template>
    </ul>

    <footer
      v-if="clientVersion != null"
      class="mt-auto mb-3 flex items-center justify-center"
    >
      <p class="text-xs text-slate-400">v{{ clientVersion }}</p>
    </footer>
  </aside>
</template>
