<template>
  <header
    v-if="environment !== 'production'"
    :class="{
      'bg-status-ativo': !['staging', 'treinamento', 'production'].includes(
        environment
      ),
      'bg-status-inativo': environment === 'staging',
      'bg-status-suspenso': environment === 'treinamento',
    }"
    class="fixed top-0 z-50 w-full text-center text-xs text-white"
  >
    <strong>Atenção: </strong> ambiente de
    <strong>
      {{ envName }}
    </strong>
  </header>
</template>

<script lang="ts" setup>
const { environment } = useRuntimeConfig();

const envName = computed((): string => {
  let name = "desenvolvimento";

  if (environment === "staging") {
    name = "homologação";
  } else if (environment === "treinamento") {
    name = "treinamento";
  } else if (environment === "production") {
    name = "produção";
  }

  return name;
});
</script>
