<template>
  <div class="relative flex">
    <UiOrganismsToast />

    <!-- com flex none, evita de "esmagar" a sidebar -->
    <UiMoleculesSidebar :items="(navItems as any[])" class="flex-none" />

    <div class="flex h-full min-h-screen max-w-full flex-auto flex-col">
      <!-- User Actions -->
      <header
        class="sticky top-0 z-40 w-full bg-white transition-all duration-700 ease-in-out"
        :class="{ shadow: hasScrolled && routeHasHeaderShadow }"
      >
        <div
          class="mx-auto flex h-16 w-full max-w-full items-center justify-end space-x-6 px-8 lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl 3xl:max-w-screen-3xl"
        >
          <!-- escondendo temporariamente botão de grid
          <div class="cursor-pointer">
            <UiIconGrid class="text-slate-400" />
          </div>
          -->

          <!-- UserIcon -->
          <Menu as="div" class="relative inline-block text-left">
            <div>
              <MenuButton
                class="h-10 w-10 cursor-pointer rounded-full p-1 shadow"
              >
                <div
                  class="grid h-full w-full place-items-center rounded-full bg-cad-magenta-500 font-bold uppercase text-white"
                >
                  {{ displayName?.slice(0, 1) }}
                </div>
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
                class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-cad-magenta-900 ring-opacity-5 focus:outline-none"
              >
                <div class="px-1 py-1">
                  <MenuItem v-slot="{ active }">
                    <button
                      @click="logout"
                      :class="{
                        'bg-cad-magenta-500 text-white': active,
                        'text-slate-700': !active,
                      }"
                      class="group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium"
                    >
                      <UiIconLogout
                        :active="active"
                        class="mr-2 h-5 w-5 text-cad-magenta-400"
                      />
                      Sair
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </header>
      <!-- Main content -->
      <main
        class="mx-auto flex h-full w-full max-w-full flex-auto flex-col px-8 lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl 2xl:px-7 3xl:max-w-screen-3xl"
      >
        <slot />
      </main>

      <footer
        class="mx-auto mt-8 flex w-full max-w-full flex-none items-center justify-end space-x-6 px-8 lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl 2xl:px-7 3xl:max-w-screen-3xl"
      >
        <div
          class="flex h-16 w-full items-center justify-end space-x-2 border-t py-6"
        >
          <span class="font-relaxed text-sm text-slate-500">
            Desenvolvido por:
          </span>
          <img width="100" src="/febrafar-logo.png" alt="febrafar logo" />
        </div>
      </footer>
    </div>

    <UiMoleculesEnvironmentLabel />
  </div>
</template>

<script setup lang="ts">
import { resolveComponent } from "@vue/runtime-core";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

import { entidadeTipoEnum } from "@/types/enums";

import useAnyListFilter from "@/store/anyListFilter";
import { getAuth } from "firebase/auth";

import "vue3-lottie/dist/style.css";

const route = useRoute();

const nuxtApp = useNuxtApp();

const routesWithHeaderShadow = [
  "redes",
  "lojas",
  "sh",
  "permissoes",
  "usuarios",
];
const hasScrolled = ref(false);

const routeHasHeaderShadow = computed(() =>
  routesWithHeaderShadow.includes(route.name.toString())
);

const displayName = computed(() => getAuth().currentUser?.displayName);

nuxtApp.hook("page:finish", () => {
  window.scrollTo(0, 0);
});

document.addEventListener("scroll", function (e) {
  if (window.scrollY > 10) {
    hasScrolled.value = true;
  } else {
    hasScrolled.value = false;
  }
});

const navItems = [
  {
    label: "Redes",
    icon: resolveComponent("UiIconRede"),
    route: "/redes",
    permissions: [entidadeTipoEnum.COLABORADOR, entidadeTipoEnum.REDE],
  },
  {
    label: "Lojas",
    icon: resolveComponent("UiIconStore"),
    route: "/lojas",
    permissions: [
      entidadeTipoEnum.COLABORADOR,
      entidadeTipoEnum.REDE,
      entidadeTipoEnum.LOJA,
    ],
  },
  {
    label: "Sócios Honorários",
    icon: resolveComponent("UiIconSh"),
    route: "/sh",
    permissions: [
      entidadeTipoEnum.COLABORADOR,
      entidadeTipoEnum.SOCIO_HONORARIO,
    ],
  },
  {
    label: "Contatos",
    icon: resolveComponent("UiIconUser"),
    route: "/usuarios",
    permissions: [
      entidadeTipoEnum.COLABORADOR,
      entidadeTipoEnum.REDE,
      entidadeTipoEnum.LOJA,
      entidadeTipoEnum.SOCIO_HONORARIO,
    ],
  },
  {
    label: "Grupos de Acesso",
    icon: resolveComponent("UiIconSetting"),
    route: "/permissoes",
    permissions: [entidadeTipoEnum.COLABORADOR],
  },
  /*
  {
    label: "Performance",
    icon: resolveComponent("UiIconRocket"),
    route: "/performance",
    permissions: [entidadeTipoEnum.COLABORADOR],
  },
  */
];

useHead({
  titleTemplate: (title) =>
    title ? `${title} | Cadastro Febrafar` : "Cadastro Febrafar",
});

const { clientVersion } = useRuntimeConfig();

declare global {
  interface Window {
    Appcues: any;
  }
}

watch(
  () => getAuth().currentUser,
  () => {
    const user = getAuth().currentUser;
    nuxtApp.$sentrySetUser(user);

    if (user != null && window.Appcues != null) {
      window.Appcues.identify(user.uid, {
        accountId: user.uid,
        firstName: user.displayName,
        email: user.email,
        version: clientVersion,
      });
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

onMounted(() => {
  useAnyListFilter.clearAll();
});

const logout = () => {
  navigateTo("/logout");
};
</script>
